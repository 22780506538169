
export const SPEED = 1;
export const SPEED_ROTATE = 0.005;
export const HALL_HEIGHT = 80;
export const HALL_SIZE_UNIT = 300;
export const HALL_SIZE_X = 400;
export const HALL_SIZE_Y = 400;
export const PHOTO_HEIGHT = 25;
export const POINTLIGHT_INTENSITY = 0.2
export const HEMISPHERELIGHT_INTENSITY = 0.3;
