import img1 from '../assets/photos/1.jpeg';
import img1_th from '../assets/photos/1_th.jpeg';
import img2 from '../assets/photos/2.jpeg';
import img2_th from '../assets/photos/2_th.jpeg';
import img3 from '../assets/photos/3.jpeg';
import img3_th from '../assets/photos/3_th.jpeg';
import img4 from '../assets/photos/4.jpeg';
import img4_th from '../assets/photos/4_th.jpeg';
import img5 from '../assets/photos/5.jpeg';
import img5_th from '../assets/photos/5_th.jpeg';
import img6 from '../assets/photos/6.jpeg';
import img6_th from '../assets/photos/6_th.jpeg';
import img7 from '../assets/photos/7.jpeg';
import img7_th from '../assets/photos/7_th.jpeg';
import img8 from '../assets/photos/8.jpeg';
import img8_th from '../assets/photos/8_th.jpeg';
import img9 from '../assets/photos/9.jpeg';
import img9_th from '../assets/photos/9_th.jpeg';
import img10 from '../assets/photos/10.jpeg';
import img10_th from '../assets/photos/10_th.jpeg';
import img11 from '../assets/photos/11.jpeg';
import img11_th from '../assets/photos/11_th.jpeg';
import img12 from '../assets/photos/12.jpeg';
import img12_th from '../assets/photos/12_th.jpeg';
import img13 from '../assets/photos/13.jpeg';
import img13_th from '../assets/photos/13_th.jpeg';
import img14 from '../assets/photos/14.jpeg';
import img14_th from '../assets/photos/14_th.jpeg';
import img15 from '../assets/photos/15.jpeg';
import img15_th from '../assets/photos/15_th.jpeg';
import img16 from '../assets/photos/16.jpeg';
import img16_th from '../assets/photos/16_th.jpeg';
import img17 from '../assets/photos/17.jpeg';
import img17_th from '../assets/photos/17_th.jpeg';
import img18 from '../assets/photos/18.jpeg';
import img18_th from '../assets/photos/18_th.jpeg';
import img19 from '../assets/photos/19.jpeg';
import img19_th from '../assets/photos/19_th.jpeg';
import img20 from '../assets/photos/20.jpeg';
import img20_th from '../assets/photos/20_th.jpeg';
import img21 from '../assets/photos/21.jpeg';
import img21_th from '../assets/photos/21_th.jpeg';
import img22 from '../assets/photos/22.jpeg';
import img22_th from '../assets/photos/22_th.jpeg';
import img23 from '../assets/photos/23.jpeg';
import img23_th from '../assets/photos/23_th.jpeg';
import img24 from '../assets/photos/24.jpeg';
import img24_th from '../assets/photos/24_th.jpeg';
import img25 from '../assets/photos/25.jpeg';
import img25_th from '../assets/photos/25_th.jpeg';
import img26 from '../assets/photos/26.jpeg';
import img26_th from '../assets/photos/26_th.jpeg';
import img27 from '../assets/photos/27.jpeg';
import img27_th from '../assets/photos/27_th.jpeg';
import img28 from '../assets/photos/28.jpeg';
import img28_th from '../assets/photos/28_th.jpeg';
import img29 from '../assets/photos/29.jpeg';
import img29_th from '../assets/photos/29_th.jpeg';
import img30 from '../assets/photos/30.jpeg';
import img30_th from '../assets/photos/30_th.jpeg';
import img31 from '../assets/photos/31.jpeg';
import img31_th from '../assets/photos/31_th.jpeg';
import img32 from '../assets/photos/32.jpeg';
import img32_th from '../assets/photos/32_th.jpeg';
// import vd33 from '../assets/photos/33.mp4';
// import img33_th from '../assets/photos/33_th.jpeg';
import img99 from '../assets/photos/99.jpeg';
import img99_th from '../assets/photos/99_th.jpeg';

const photoData = [
    {
        "photo_id": 1,
        "photograper": "20김도연",
        "title": "속초의 밤 첫 소관",
        "location": "강원도 속초시",
        "date": "2020-10-17T15:00:00.000Z",
        "equipment": "Sony ICLE-7M2, Voigtlander Nokton Classic 35mm F1.4 FE",
        "exposure": "44s, ISO 800",
        "processing": "Adobe Photoshop CC 2020",
        "story": "첫 소관에 가서 찍은 사진입니다. 아무 장비도 없이 떠난 소관에서 지용선배의 카메라로 찍은 사진입니다!",
        "file_path": img1,
        "thumbnail_path": img1_th,
        "xPos": 170,
        "yPos": 0,
        "zPos": -127,
        "rotation": -144,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 2,
        "photograper": "17정민용",
        "title": "홀로서기",
        "location": "강원도 속초시",
        "date": "2020-10-16T15:00:00.000Z",
        "equipment": "Sony A7 III, Laowa 15mm f/2 Zero-D",
        "exposure": "F/4 30s ISO3200",
        "processing": "Adobe Photoshop CC 2020",
        "story": "홀로노믹 구속에 사로잡힌 소나무",
        "file_path": img2,
        "thumbnail_path": img2_th,
        "xPos": 205,
        "yPos": 0,
        "zPos": -152,
        "rotation": -144,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 3,
        "photograper": "18임성진",
        "title": "겨울 별 떠오를 적에",
        "location": "충청남도 홍성군",
        "date": "2020-09-26T15:00:00.000Z",
        "equipment": "Sony a7, Samyang 12mm T3.1 fisheye",
        "exposure": "ISO2000, T3.1, 30s*14 ",
        "processing": "Sequator, Photoshop",
        "story": "밤 공기가 쌀쌀해지면 겨울 별들도 떠오를 준비를 합니다.",
        "file_path": img3,
        "thumbnail_path": img3_th,
        "xPos": 225,
        "yPos": 0,
        "zPos": -200,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 4,
        "photograper": "19김도은",
        "title": "이야기들은 피어올라 별이 되어",
        "location": "경기도 안산시",
        "date": "2020-08-16T15:00:00.000Z",
        "equipment": "Canon EOS M3",
        "exposure": "ISO1600, F/3.5, 15s",
        "processing": "Adobe Lightroom Classic",
        "story": "술과 별과 친구가 함께한 미드나잇 캠핑",
        "file_path": img4,
        "thumbnail_path": img4_th,
        "xPos": 211,
        "yPos": 0,
        "zPos": -245,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 5,
        "photograper": "19김도은",
        "title": "별들의 잔해",
        "location": "강원도 화천군",
        "date": "2020-03-28T15:00:00.000Z",
        "equipment": "Canon EOS M3",
        "exposure": "ISO6400, F/6.3, 20s",
        "processing": "Adobe Lightroom Classic",
        "story": "외로운 거인은 별들의 잔해를 거두어 리라를 만들었더랍니다.",
        "file_path": img5,
        "thumbnail_path": img5_th,
        "xPos": 171,
        "yPos": 0,
        "zPos": -282.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 6,
        "photograper": "19정휘원",
        "title": "이카루스의 꿈",
        "location": "강원도 속초시",
        "date": "2020-10-16T15:00:00.000Z",
        "equipment": "Canon EOS 700D, Samyang 14mm",
        "exposure": "15s, ISO800, F/2.8",
        "processing": "Adobe Photoshop, Adobe Lightroom",
        "story": "떠오른 오리온을 바라보는 저를 찍었습니다. 등의 날개를 강조하고 싶었는데 어둡게 찍혀서 조금 아쉽네요. 언젠간 바라보는 것에 그치지 않고 닿을 수 있기를 바랍니다.",
        "file_path": img6,
        "thumbnail_path": img6_th,
        "xPos": 125,
        "yPos": 0,
        "zPos": -282.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 7,
        "photograper": "17정민용",
        "title": "자화상",
        "location": "강원도 인제군",
        "date": "2020-06-20T15:00:00.000Z",
        "equipment": "VOIGTLANDER 35MM 1.4 NOKTON E CLASSIC",
        "exposure": "F/4 30s ISO4000",
        "processing": "Adobe Photoshop CC 2020",
        "story": "우물 속에는 달이 밝고 구름이 흐르고 하늘이 펼치고 파아란 바람이 불고 가을이 있고 추억처럼 사나이가 있습니다.",
        "file_path": img7,
        "thumbnail_path": img7_th,
        "xPos": 85,
        "yPos": 0,
        "zPos": -260,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 8,
        "photograper": "18윤홍식",
        "title": "집으로 가는 길",
        "location": "강원도 백마고지",
        "date": "2020-04-25T15:00:00.000Z",
        "equipment": "Sony a5100, Sony E PZ 16-50mm ",
        "exposure": "ISO 400 F/4.0 30s",
        "processing": "Adobe Lightroom CC",
        "story": "일본의 어느 시골에 외할머니가 사시는데, 집으로 돌아가는 길이 딱 이런 느낌이었다.",
        "file_path": img8,
        "thumbnail_path": img8_th,
        "xPos": 72,
        "yPos": 0,
        "zPos": -220,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 9,
        "photograper": "16황준",
        "title": "Peppers Bluewater Resort",
        "location": "Tekapo, New Zealand",
        "date": "2019-12-19T15:00:00.000Z",
        "equipment": "Canon EOS 700D, Tokina 11-16mm f/2.8 AT-X Pro DX II (@11mm)",
        "exposure": "ISO3200 F/3.2 43s",
        "processing": "Adobe Photoshop CC",
        "story": "터쿼이즈 빛깔의 아름다운 물빛을 자랑하는 lake Tekapo!! 는 밤에는 전혀 보이지 않습니다. 대신 호수 옆 숙소에서도 이렇게나 멋진 밤하늘이 보입니다.",
        "file_path": img9,
        "thumbnail_path": img9_th,
        "xPos": 59,
        "yPos": 0,
        "zPos": -180,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 10,
        "photograper": "17정민용",
        "title": "달려야 은하수야",
        "location": "강원도 인제군",
        "date": "2020-06-20T15:00:00.000Z",
        "equipment": "Sony A7 III, \nLaowa 15mm f/2 Zero-D",
        "exposure": "F/4 30s*101 ISO1600",
        "processing": "Adobe Photoshop CC 2020",
        "story": "별들은 달리지만 은하수는 달리지 않습니다.",
        "file_path": img10,
        "thumbnail_path": img10_th,
        "xPos": 53,
        "yPos": 0,
        "zPos": -180,
        "rotation": -108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 11,
        "photograper": "17정민용",
        "title": "달려라 울산바위",
        "location": "강원도 속초시",
        "date": "2020-10-16T15:00:00.000Z",
        "equipment": "Sony A7 III, \nLaowa 15mm f/2 Zero-D",
        "exposure": "F/4 30s*300 ISO1600",
        "processing": "Adobe Photoshop CC 2020",
        "story": "별들은 달리지만 울산바위는 달리지 않습니다. ",
        "file_path": img11,
        "thumbnail_path": img11_th,
        "xPos": 66,
        "yPos": 0,
        "zPos": -220,
        "rotation": -108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 12,
        "photograper": "19정현우",
        "title": "까치집",
        "location": "강원도 백마고지",
        "date": "2020-04-25T15:00:00.000Z",
        "equipment": "Sony a6000, Samyang 12mm f2.0",
        "exposure": "1h",
        "processing": "Adobe Lightroom CC 2020",
        "story": "앙상한 가지 사이에 매달린 까치집이 보입니다. 여기서 태어난 새끼들은 별을 보며 자랐겠군요. 부럽습니다.",
        "file_path": img12,
        "thumbnail_path": img12_th,
        "xPos": 79,
        "yPos": 0,
        "zPos": -260,
        "rotation": -108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 13,
        "photograper": "19정휘원",
        "title": "옛 길의 시작",
        "location": "강원도 속초시",
        "date": "2020-10-16T15:00:00.000Z",
        "equipment": "Canon EOS 700D, Samyang 14mm",
        "exposure": "30s*44, ISO400, F/2.8",
        "processing": "Adobe photoshop&lightroom, Starstax",
        "story": "울산바위로 향하는 길의 초입에 있던 벤치 위로 쏟아지는 서쪽의 별들을 담았습니다. 푸른 별빛과 대비되는 붉은 지상의 색감이 좋습니다. ",
        "file_path": img13,
        "thumbnail_path": img13_th,
        "xPos": 80,
        "yPos": 0,
        "zPos": -316,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 14,
        "photograper": "19정휘원",
        "title": "숲의 양옥집",
        "location": "강원도 속초시",
        "date": "2020-10-16T15:00:00.000Z",
        "equipment": "Canon EOS 700D, Samyang 14mm",
        "exposure": "25s*89, ISO200, F/2.8",
        "processing": "Adobe photoshop&lightroom, Starstax",
        "story": "울산바위의 입구에 있었던 폐건물을 배경으로 떠오르는 별들의 궤적을 담았습니다.  은은한 청색 색감이 마음에 듭니다.",
        "file_path": img14,
        "thumbnail_path": img14_th,
        "xPos": 67,
        "yPos": 0,
        "zPos": -355,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 15,
        "photograper": "19박서진",
        "title": "백우",
        "location": "경기도 파주시",
        "date": "2020-04-03T15:00:00.000Z",
        "equipment": "Sony a5100",
        "exposure": "F/3.5 15sec ISO250",
        "processing": "Adobe Photoshop CC 2020",
        "story": "눈내리는 봄이라는 코멘트가 인상적이었던 사진",
        "file_path": img15,
        "thumbnail_path": img15_th,
        "xPos": 20,
        "yPos": 0,
        "zPos": -387.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 16,
        "photograper": "18임성진",
        "title": "밤 공연",
        "location": "서울대학교 풍산마당",
        "date": "2020-01-04T15:00:00.000Z",
        "equipment": "Sony a5000, Samyang 12mm",
        "exposure": "ISO250, F4, 25s*154 ",
        "processing": "Adobe photoshop&lightroom, Starstax",
        "story": "공연이 끝난 뒤, 고요한 무대와 빛나는 별.",
        "file_path": img16,
        "thumbnail_path": img16_th,
        "xPos": -20,
        "yPos": 0,
        "zPos": -387.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 17,
        "photograper": "16황준",
        "title": "Stream of Towers",
        "location": "강남구 양재천",
        "date": "2020-01-13T15:00:00.000Z",
        "equipment": "Sony A7R3, SIGMA A 14mm F1.8 DG HSM",
        "exposure": "ISO80 F/4.5 25s×154 (약 1시간)",
        "processing": "StarStaX 합성, Adobe Photoshop CC 보정",
        "story": "개울을 따라 늘어선 타워팰리스를 따라가면 곧 롯데타워가 나올 것 같지만 실제론 걸어서 1시간 반 정도 걸립니다.",
        "file_path": img17,
        "thumbnail_path": img17_th,
        "xPos": -67,
        "yPos": 0,
        "zPos": -355,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 18,
        "photograper": "18윤홍식",
        "title": "숲의 눈동자",
        "location": "전라남도 담양",
        "date": "2020-06-07T15:00:00.000Z",
        "equipment": "Sony a5100, Sony E PZ 16-50mm ",
        "exposure": "ISO 400 F/4.0 30s*80장",
        "processing": "Adobe Lightroom CC, Starstax",
        "story": "친구랑 오두막처럼 생긴 숙소에서 묵으면서 찍었던 사진.",
        "file_path": img18,
        "thumbnail_path": img18_th,
        "xPos": -80,
        "yPos": 0,
        "zPos": -316,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 19,
        "photograper": "17황원희",
        "title": "일상으로 다시",
        "location": "서울특별시 성북구 성북동",
        "date": "2020-10-04T15:00:00.000Z",
        "equipment": "Sony A7 II, Sony FE 3.5-5.6/28-70 OSS 28mm",
        "exposure": "ISO 50, f/5.0, 30sec*149장 약75분 합성",
        "processing": "Sequator 합성, Adobe Lightroom Classic, Adobe Photoshop 2021 보정",
        "story": "추석연휴가 끝나고, 다시 일상으로 돌아갈 날의 새벽.",
        "file_path": img19,
        "thumbnail_path": img19_th,
        "xPos": -79,
        "yPos": 0,
        "zPos": -260,
        "rotation": 108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 20,
        "photograper": "17황원희",
        "title": "새벽산책",
        "location": "서울특별시 성북천",
        "date": "2020-10-05T15:00:00.000Z",
        "equipment": "Sony A7 II, Sony FE 3.5-5.6/28-70 OSS 43mm",
        "exposure": "ISO 50, f/6.3, 15sec*591장 약150분 합성",
        "processing": "Sequator 합성, Adobe Lightroom Classic, Adobe Photoshop 2021 보정",
        "story": "새벽에도 많은 사람들이 산책하는 이 길에 별들도 같이 산책을 합니다.",
        "file_path": img20,
        "thumbnail_path": img20_th,
        "xPos": -66,
        "yPos": 0,
        "zPos": -220,
        "rotation": 108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 21,
        "photograper": "17황원희",
        "title": "시간여행",
        "location": "서울특별시 청계천",
        "date": "2020-10-07T15:00:00.000Z",
        "equipment": "Sony A7 II, Sony FE 3.5-5.6/28-70 OSS 28mm",
        "exposure": "ISO 50, f/5.6, 30sec*323장 약160분 합성",
        "processing": "Sequator 합성, Adobe Lightroom Classic, Adobe Photoshop 2021 보정",
        "story": "지금은 해체가 된 청계고가도로의 다리기둥을 보면 청계천의 옛 모습으로 시간여행을 해 버릴 것 같습니다.",
        "file_path": img21,
        "thumbnail_path": img21_th,
        "xPos": -53,
        "yPos": 0,
        "zPos": -180,
        "rotation": 108,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 22,
        "photograper": "19김도은",
        "title": "Cyberpunk Moonnight 3020",
        "location": "서울시 영등포구",
        "date": "2020-04-07T15:00:00.000Z",
        "equipment": "Canon EOS M3",
        "exposure": "ISO100, F/6.3, 1/100s",
        "processing": "Adobe Photoshop CC, Lightroom Classic",
        "story": "천 년 후에도 달은 뜨겠지요",
        "file_path": img22,
        "thumbnail_path": img22_th,
        "xPos": -59,
        "yPos": 0,
        "zPos": -180,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 23,
        "photograper": "19박서진",
        "title": "달과 자하연",
        "location": "서울대학교 자하연",
        "date": "2020-04-07T15:00:00.000Z",
        "equipment": "Sony a5100, @50mm",
        "exposure": "ISO 200 5sec f/5.6",
        "processing": "Adobe Photoshop CC 2020",
        "story": "[타원 궤도를 도는 달은 지구와 근지점 통과할 때 커보여] 올해의 제일 큰 달을 담아보았습니다",
        "file_path": img23,
        "thumbnail_path": img23_th,
        "xPos": -72,
        "yPos": 0,
        "zPos": -220,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 24,
        "photograper": "19정현우",
        "title": "너의 혜성은",
        "location": "충청남도 서산시",
        "date": "2020-07-15T15:00:00.000Z",
        "equipment": "Sony a6000, 1650 standard",
        "exposure": "15s",
        "processing": "Adobe Lightroom CC 2020",
        "story": "네오와이즈 혜성을 찍어보았습니다. 생각보다 고도가 낮아서 그리 밝게 찍히진 않았습니다. 그래도 제 손으로 찍은 첫 혜성사진이기에 너무 기뻤습니다.",
        "file_path": img24,
        "thumbnail_path": img24_th,
        "xPos": -85,
        "yPos": 0,
        "zPos": -260,
        "rotation": -72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 25,
        "photograper": "16윤지용",
        "title": "금환일식 다중노출",
        "location": "Cetti Bay Overlook, Guam",
        "date": "2019-12-25T15:00:00.000Z",
        "equipment": "Sony A7 II, Samyang 14mm f/2.8",
        "exposure": "다중노출 (16장)",
        "processing": "Adobe Photoshop CC 2020",
        "story": "금환일식을 다중노출로 촬영한 사진이다. Thanks to 황원희 (태양 필터)",
        "file_path": img25,
        "thumbnail_path": img25_th,
        "xPos": -125,
        "yPos": 0,
        "zPos": -282.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 26,
        "photograper": "18배장호",
        "title": "중심",
        "location": "강원도 인제군",
        "date": "2020-06-21T15:00:00.000Z",
        "equipment": "PENTAX K-3II, PENTAX DA 12-24mm f/4 @21mm",
        "exposure": "F/4, 24분, ISO 800",
        "processing": "포토샵, 라이트룸",
        "story": "여러 장을 합성하고 보정도 열심히 한, 올해 가장 정성들여 찍은 사진입니다. 은하수 중심부와 전갈자리 부근이 잘 나와서 마음에 듭니다.",
        "file_path": img26,
        "thumbnail_path": img26_th,
        "xPos": -171,
        "yPos": 0,
        "zPos": -282.5,
        "rotation": 0,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 27,
        "photograper": "19김도은",
        "title": "보슈",
        "location": "강원도 철원군",
        "date": "2020-04-25T15:00:00.000Z",
        "equipment": "Canon EOS M3, Celestron Nexstar 90GT",
        "exposure": "Light 30s*84, Flat 30s*36, Dark 30s*18",
        "processing": "DeepSkyStacker, Adobe Lightroom Classic",
        "story": "보데 시가좀 보시유!",
        "file_path": img27,
        "thumbnail_path": img27_th,
        "xPos": -211,
        "yPos": 0,
        "zPos": -245,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 28,
        "photograper": "19박서진",
        "title": "부유하는 세상의 먼지",
        "location": "김태영 기념 관측소(돔)",
        "date": "2020-10-17T15:00:00.000Z",
        "equipment": "Canon 700d",
        "exposure": "F/3.5 iso1600 1hr",
        "processing": "Adobe Photoshop CC 2020, Maxim DL, ",
        "story": "보이지 않는 장미를 향해 hophop",
        "file_path": img28,
        "thumbnail_path": img28_th,
        "xPos": -225,
        "yPos": 0,
        "zPos": -200,
        "rotation": 72,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 29,
        "photograper": "18서성일",
        "title": "Dreamwrapt Breakfast",
        "location": "강원도 인제군",
        "date": "2020-09-18T15:00:00.000Z",
        "equipment": "LEO 80, 삼성 NX-20",
        "exposure": "ISO 1600 1시간 25분",
        "processing": "Adobe Photoshop CC 2020",
        "story": "해가 지면 수고한 자신을 내 자신은 눈 감아 휴식을 취할 때, 머리 속 또 다른 나는 18시간 만에 깨어나 칠흑 속 수많은 별들이 그를 맞이해준다. 수많은 별들 중 그의 눈에 들어온 것은, 미라크(Mirach) 위의 M31 은하, 푸른 후라이팬 가운데에 계란을 깨고 우유를 부은 부드러운 스크램블 에그…   꼬르륵… “하지만 지금은 내 아침이죠”",
        "file_path": img29,
        "thumbnail_path": img29_th,
        "xPos": -205,
        "yPos": 0,
        "zPos": -152,
        "rotation": 144,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 30,
        "photograper": "18서성일",
        "title": "Altarian Rift",
        "location": "강원도 인제군",
        "date": "2020-09-18T15:00:00.000Z",
        "equipment": "LEO 80, 삼성 NX-20",
        "exposure": "ISO 1600 2시간 4분",
        "processing": "Adobe Photoshop CC 2020",
        "story": "",
        "file_path": img30,
        "thumbnail_path": img30_th,
        "xPos": -170,
        "yPos": 0,
        "zPos": -127,
        "rotation": 144,
        "can_be_voted": true,
        "is_video": false
    },
    {
        "photo_id": 31,
        "photograper": "13서유경(OB)",
        "title": "낙성대 충무공(落星垈 衝武功)",
        "location": "서울 관악구 낙성대공원",
        "date": "2020-11-09T15:00:00.000Z",
        "equipment": "SonyA5000 + SIGMA 17-70 MACRO DC",
        "exposure": "ISO400, F7.1, 15초 * 1013장",
        "processing": "StarStaX Gapfilling 합성 + Photoshop CC 2020 보정",
        "story": "서쪽하늘로 내려오며, 낙성대 공원의 강감찬상을 관통하는 궤적은 다름아니라 화성이다.\n고려시대 무신으로 업적을 남긴 강감찬 장군의 사당이 있는 낙성대에서, 떨어지는 별들과 함께 전쟁을 상징하는 화성이, 강감찬 장군상을 관통하게 찍었다.\n제목을 보고, 동상의 주인공이 누구인지 바로 알 수 있게 낙성대 충무공이라 지었다.\n그리고 충무공에 쓰인 한자는, 충성을 뜻하는 한자가 아니라 외행성의 위치를 나타내는 '충(衝)' 이다. 그리고 이 한자는 이전에 적진이나 성(城)을 공격할 때 쓰던 공성용 수레를 가리키는 말이기도 하다.",
        "file_path": img31,
        "thumbnail_path": img31_th,
        "xPos": -121,
        "yPos": 0,
        "zPos": -82,
        "rotation": 131,
        "can_be_voted": false,
        "is_video": false
    },
    {
        "photo_id": 32,
        "photograper": "19이남경(OB)",
        "title": "흑상어",
        "location": "강원도 인제군",
        "date": "2020-09-18T15:00:00.000Z",
        "equipment": "D610 skyrover70SA ",
        "exposure": "3시간 45분",
        "processing": "Pixinsight ",
        "story": "3달간 5번정도 도전해보다 간신히 찍어낸 검은상어 성운입니다. 암흑성운 계열이기에 상당히 어둡기도하고 찍는날이면 날마다 위치와 시간이 애매해 충분한 노출시간을 확보하기 어려웠던 대상이였습니다만 성공적으로 찍어내 기쁘군요.",
        "file_path": img32,
        "thumbnail_path": img32_th,
        "xPos": -90,
        "yPos": 0,
        "zPos": -46,
        "rotation": 131,
        "can_be_voted": false,
        "is_video": false
    },
    // {
    //     "photo_id": 33,
    //     "photograper": "12권효상(OB)",
    //     "title": "Stars On",
    //     "location": "대한민국 강원도/경기도",
    //     "date": "",
    //     "equipment": "Canon EOS 60D",
    //     "exposure": "Adobe Lightloom, Premier pro, Photoshop, Startrail, ",
    //     "processing": "",
    //     "story": "",
    //     "file_path": vd33,
    //     "thumbnail_path": img33_th,
    //     "xPos": -58.5,
    //     "yPos": 0,
    //     "zPos": -10,
    //     "rotation": 131,
    //     "can_be_voted": false,
    //     "is_video": true
    // },
    {
        "photo_id": 99,
        "photograper": "20학번 신입생 일동",
        "title": "",
        "location": "",
        "date": "",
        "equipment": "",
        "exposure": "",
        "processing": "",
        "story": "신입생 친구들과 함께 모은 밤하늘 사진으로 배경을 만들어 모두가 함께 참여하는 신입생 프로젝트의 의미를 담았습니다. 곧 다가오는 겨울철의 대 삼각형을 형상화해 AAA의 로고를 표현했는데, 점자로 표현한 글자 A가 마치 별자리 같기도 해 함께 만들어간 배경의 밤하늘과 잘 어울린다고 생각했습니다. 비록 몸은 멀리있지만 여러분들이 함께해 더욱 의미가 깊었던 신입생 프로젝트였던 것 같습니다.",
        "file_path": img99,
        "thumbnail_path": img99_th,
        "xPos": 90,
        "yPos": 0,
        "zPos": -46,
        "rotation": -131,
        "can_be_voted": false,
        "is_video": false
    }
]

export default photoData;